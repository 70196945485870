import { TNikonVariant } from 'types/TNikonProduct';

export const getPLPLabeledVariant = (variants: TNikonVariant[]) =>
  variants.find((variant) => variant.representativeImage != null);

export const getVariantPLP = (variants: TNikonVariant[]) => getPLPLabeledVariant(variants) || variants[0];

export const getSwatchImages = (variants: TNikonVariant[]) => {
  const variantsFilteredBySwatchImage = variants.filter((item) => item.swatchImage != null);
  if (variantsFilteredBySwatchImage.length <= 1) {
    return [];
  }

  return variantsFilteredBySwatchImage.map((item) => ({ id: item.id, image: item.swatchImage }));
};

export const optionsAvailable = (variants: TNikonVariant[]): { show: boolean; count?: number } => {
  return { show: variants.length > 1, count: variants.length };
};

export const getPromoMessage = (variants: TNikonVariant[]) => {
  const variantsWithPromoMessage = variants.filter((variant) => variant.attributes.promoMessage != null);

  if (variantsWithPromoMessage.length === 1 && variants.length === 1) {
    return variants[0].attributes.promoMessage;
  }

  if (variantsWithPromoMessage.length >= 2) {
    return 'Multiple Promotions Available';
  }
};
