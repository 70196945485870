import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import styles from './Pagination.module.scss';
import { PaginationProps } from './types/PaginationTypes';

const Pagination = ({
  pagination,
  offsetKey = 'offset',
  limitKey = 'limit',
  scrollToSection,
  sort,
  onLoading,
  scroll,
  showLoader = true,
}: PaginationProps) => {
  const router = useRouter();
  const { limit, offset, total } = pagination;
  const pathname = router.asPath.split('?')[0];
  const { slug, ...routerQuery } = router.query;

  const [loading, setLoading] = useState(false);

  const handleStart = () => {
    setLoading(true);
    onLoading?.(true);
  };

  const handleComplete = useCallback(() => {
    setLoading(false);
    onLoading?.(false);
  }, []);

  useEffect(() => {
    if (loading) {
      router.events.on('routeChangeComplete', handleComplete);
      router.events.on('routeChangeError', handleComplete);

      return () => {
        router.events.off('routeChangeComplete', handleComplete);
        router.events.off('routeChangeError', handleComplete);
      };
    }
  }, [router.events, loading, handleComplete]);

  if (limit < total) {
    const pagination = [];
    const totalNumPages = Math.ceil(total / limit);
    const currentPage = offset / limit + 1 || 1;
    const separator = { label: '...', active: false, query: {} };

    const pushPage = (i: number) => {
      pagination.push({
        label: (i + 1).toString(),
        active: i + 1 === currentPage,
        query: {
          [limitKey]: limit,
          [offsetKey]: i * limit,
        },
      });
    };

    if (totalNumPages > 5) {
      // previous arrow (disabled if on first page)
      pagination.push({
        label: '<',
        active: currentPage > 1,
        query: {
          [limitKey]: limit,
          [offsetKey]: (currentPage - 2) * limit,
        },
      });

      // always show page 1
      pagination.push({
        label: '1',
        active: 1 === currentPage,
        query: {
          [limitKey]: limit,
          [offsetKey]: undefined,
        },
      });

      // numbers
      if (currentPage < 4) {
        // 1 2 3 4 ... totalNumPages
        for (let i = 1; i < 4; i++) {
          pushPage(i);
        }
        pagination.push(separator);
      } else if (currentPage > totalNumPages - 3) {
        // 1 ... (totalNumPages-3) (totalNumPages-2) (totalNumPages-1) totalNumPages
        pagination.push(separator);
        for (let i = totalNumPages - 4; i < totalNumPages - 1; i++) {
          pushPage(i);
        }
      } else {
        // 1 ... (currentPage-1) currentPage (currentPage+1) ... totalNumPages
        pagination.push(separator);
        for (let i = currentPage - 2; i < currentPage + 1; i++) {
          pushPage(i);
        }
        pagination.push(separator);
      }

      // always show last page
      pagination.push({
        label: totalNumPages.toString(),
        active: totalNumPages === currentPage,
        query: {
          [limitKey]: limit,
          [offsetKey]: (totalNumPages - 1) * limit,
        },
      });

      // next arrow (disabled if on last page)
      pagination.push({
        label: '>',
        active: currentPage < totalNumPages,
        query: {
          [limitKey]: limit,
          [offsetKey]: currentPage * limit,
        },
      });
    } else {
      // 5 pages or fewer, just show the pagination
      for (let i = 0; i < totalNumPages; i++) {
        pushPage(i);
      }
    }

    const getSanitizeQuery = (query: Record<string, string | string[] | undefined>) => {
      const sanitizedQuery = { ...query };
      Object.keys(sanitizedQuery).forEach((key) => {
        if (sanitizedQuery[key] === undefined) delete sanitizedQuery[key];
      });
      return sanitizedQuery;
    };

    return (
      <>
        {!loading || !showLoader ? (
          <ul className={`${styles.pagination} pagination`} data-testid={`pagination`}>
            {pagination.map((item, index) => {
              let content = item.label;
              let classList = item.active ? 'active' : '';
              if (item.label === '<') {
                content = '<span class="ss-navigateleft"></span>';
                classList = item.active ? '' : 'disabled';
              } else if (item.label === '>') {
                content = '<span class="ss-navigateright"></span>';
                classList = item.active ? '' : 'disabled';
              }
              return (
                <li key={`page-${index}`} className={classList}>
                  {item.label === '...' ? (
                    '...'
                  ) : (
                    <Link
                      href={{
                        pathname,
                        query: getSanitizeQuery({ ...routerQuery, sort, ...(item.query || {}) }),
                        hash: scrollToSection,
                      }}
                      scroll={scroll ?? (scrollToSection ? false : undefined)}
                    >
                      <a onClick={handleStart} dangerouslySetInnerHTML={{ __html: content }}></a>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <div className={styles.loading}>
            <LoadingIcon className="fill-gray-700" />
          </div>
        )}
      </>
    );
  } else return null;
};

export default Pagination;
