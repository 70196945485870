import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import { IMAGE_SOURCE } from 'helpers/constants/imageSources';
import useVariantWithDiscount from 'helpers/hooks/useVariantWithDiscount';
import { TNikonProduct, TNikonVariant } from 'types/TNikonProduct';
import { Money } from '../../../../../../types/product/Money';
import { getSwatchImages } from '../product-tile/utils';

const useProductListItem = (product: TNikonProduct) => {
  const router = useRouter();
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(router.locale);

  const variantWithDiscount = useVariantWithDiscount(product.variants);
  const [selectedVariant, setSelectedVariant] = useState<TNikonVariant>(
    () => (variantWithDiscount as TNikonVariant) ?? product?.variants?.[0],
  );

  const swatchImages = useMemo(() => getSwatchImages(product.variants), [product]);

  const setVariantById = (id: string) => {
    const variant = product.variants.find((item) => item.id === id) as TNikonVariant;
    setSelectedVariant(variant);
  };

  const productUrl = useMemo(() => {
    if (!product._url) {
      return '#';
    }

    return `${product._url}/overview`;
  }, [product._url]);

  const isSale = useMemo(
    () => product?.variants.some((p: TNikonVariant) => p.discountedPrice || p.price?.custom?.fields?.basePrice),
    [product],
  );

  const isNew = useMemo(() => {
    const newUntil = dayjs(product.newUntil);

    return newUntil.isAfter(dayjs());
  }, [product]);

  const lowerPrice = useMemo(() => {
    return product?.variants.reduce((acc: Money, variant) => {
      const price = variant.discountedPrice ?? variant.price;
      const isDealerExclusive = Boolean(variant?.attributes?.dealerExclusive);

      if (price?.centAmount && !isDealerExclusive && acc.centAmount && price.centAmount < acc.centAmount) {
        return price;
      }
      return acc;
    }, product?.variants[0]?.price);
  }, [product]);

  const productName = useMemo(() => {
    const wordToSplitBy = 'nikkor';
    const name = product?.name?.toLocaleLowerCase() || '';

    if (name.includes(wordToSplitBy)) {
      const index = name.indexOf(wordToSplitBy) + wordToSplitBy.length;

      return (
        <>
          {product.name.substring(0, index + 1)}
          <br />
          {product.name.substring(index + 1)}
        </>
      );
    }

    return <>{product?.name}</>;
  }, [product]);

  const promotions = useMemo(() => {
    return product?.variants.reduce(
      (acc, variant) => {
        if (variant.discountedPrice) {
          const discountedPrice = variant.discountedPrice?.centAmount ?? 0;
          const price = variant.price?.centAmount ?? 0;
          return { amount: acc.amount + 1, save: price - discountedPrice };
        }
        return acc;
      },
      { amount: 0, save: 0 },
    );
  }, [product]);

  return {
    imageUnavailable,
    selectedVariant,
    setVariantById,
    swatchImages,
    productUrl,
    isSale,
    isNew,
    lowerPrice,
    productName,
    promotions,
  };
};

export default useProductListItem;
