import { FC } from 'react';
import { useRouter } from 'next/router';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useLocale } from 'helpers/hooks/useLocale';
import {
  getProductDiscountsByPosition,
  getVariantLowerPrice,
  hasMultiplePromoMessages,
} from '../../../../../../helpers/productHelpers';
import { IProductTileProps } from '../interfaces';
import styles from '../ProductTile.module.scss';

interface PriceProps extends Pick<IProductTileProps, 'product'> {
  showPrice: boolean;
}
const Price: FC<PriceProps> = ({ showPrice, product }) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const router = useRouter();
  const { isUsaStore, isCanadaStore } = useLocale();

  if (!showPrice) {
    return null;
  }

  const variantLowerPrice = getVariantLowerPrice(product.variants);

  const haveMultipleVariants = product.variants.length > 1;
  const variantDiscountedPrice = variantLowerPrice?.discountedPrice;
  const customBasePrice = variantLowerPrice?.price?.custom?.fields?.basePrice;
  const price = customBasePrice || variantLowerPrice?.price;
  const discountedPrice = customBasePrice
    ? variantDiscountedPrice // This is when the product have both discounts configured, a discount price and a marketing copy discount
      ? variantDiscountedPrice
      : variantLowerPrice?.price
    : variantDiscountedPrice || undefined;
  const startingAtPrice = discountedPrice || price;

  if (!price?.centAmount) {
    return null;
  }

  const marketingCopy = variantLowerPrice.price.custom?.fields?.marketingCopy;
  const marketingCopyDiscount = variantLowerPrice?.price?.custom?.fields?.basePrice;

  const browMessage =
    variantLowerPrice.discounts && getProductDiscountsByPosition(variantLowerPrice.discounts[0])?.brow;

  const isShowDiscount = Boolean(discountedPrice && (isUsaStore || isCanadaStore) && !haveMultipleVariants);

  const isShowMultiplePromoMessage = Boolean(
    (isUsaStore || isCanadaStore) && hasMultiplePromoMessages(product.variants),
  );
  const isShowMarketingCopy = Boolean(isShowDiscount && !isShowMultiplePromoMessage && marketingCopy);
  const isShowBrowMessage = Boolean(isShowDiscount && !isShowMultiplePromoMessage && browMessage);
  const isShowYouSaveMessage =
    isShowDiscount &&
    !isShowMultiplePromoMessage &&
    marketingCopyDiscount &&
    !isShowMarketingCopy &&
    !isShowBrowMessage;

  return (
    <>
      {haveMultipleVariants && (
        <div className={styles['price']}>
          {formatMessage({
            id: 'product.startingAt',
            defaultMessage: 'Starting at {price}',
            values: { price: CurrencyHelpers.formatForCurrency(startingAtPrice, router.locale) },
          })}
        </div>
      )}

      {isShowDiscount && discountedPrice && (
        <>
          {isUsaStore && (
            <>
              <span className={styles['price-was']}>{CurrencyHelpers.formatForCurrency(price, router.locale)}</span>
              <div className={styles['price']}>{CurrencyHelpers.formatForCurrency(discountedPrice, router.locale)}</div>
            </>
          )}
          {isCanadaStore && (
            <>
              <div className="text-sm font-bold">
                {formatMessage({ id: 'sale.now', defaultMessage: 'Now' })}{' '}
                {CurrencyHelpers.formatForCurrency(discountedPrice, router.locale)}
              </div>
              <span className="text-xs">
                {formatMessage({ id: 'sale.was', defaultMessage: 'Reg.' })}{' '}
                {CurrencyHelpers.formatForCurrency(price, router.locale)}
              </span>
            </>
          )}

          <div className={styles['you-save']}>
            {isShowYouSaveMessage && (
              <>
                {formatMessage({
                  id: 'product.youSave',
                  defaultMessage: 'You Save',
                })}
                &nbsp;
                {CurrencyHelpers.formatForCurrency(
                  CurrencyHelpers.subtractCurrency(price, discountedPrice),
                  router.locale,
                )}
              </>
            )}
            {isShowMultiplePromoMessage &&
              formatMessage({
                id: 'product.multiplePromotions',
                defaultMessage: 'Multiple Promotions Available',
              })}

            {isShowMarketingCopy && marketingCopy}
            {isShowBrowMessage && browMessage}
          </div>
        </>
      )}

      {haveMultipleVariants && isShowMultiplePromoMessage && (
        <div className={styles['you-save']}>
          {formatMessage({
            id: 'product.multiplePromotions',
            defaultMessage: 'Multiple Promotions Available',
          })}
        </div>
      )}

      {!haveMultipleVariants && !isShowDiscount && !isShowMultiplePromoMessage && (
        <div className={styles['price']}>{CurrencyHelpers.formatForCurrency(price, router.locale)}</div>
      )}
    </>
  );
};

export default Price;
