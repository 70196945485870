import { FC } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useLocale } from 'helpers/hooks/useLocale';
import Price from './Price';
import { Money } from '../../../../../../../types/product/Money';
import useProductListItem from '../../hooks/useProductListItem';
import { IProductTileProps } from '../interfaces';
import styles from '../ProductTile.module.scss';
import { getPromoMessage, optionsAvailable } from '../utils';

interface ProductDescriptionProps extends Pick<IProductTileProps, 'product'> {
  isSale: boolean;
  isNew: boolean;
  lowerPrice: Money;
}
const ProductDescription: FC<ProductDescriptionProps> = ({ isSale, isNew, product, lowerPrice }) => {
  const { productName } = useProductListItem(product);
  const { formatMessage } = useFormat({ name: 'product' });
  const promoMessage = getPromoMessage(product.variants);
  const currentOptionsAvailable = optionsAvailable(product.variants);
  const { isUsaStore, isCanadaStore } = useLocale();

  const showPrice = isUsaStore || isCanadaStore;
  const showSaleText = isUsaStore || isCanadaStore;

  return (
    <div className={styles['description']}>
      <div className={styles['title']}>
        {isSale && showSaleText && (
          <span className={styles.newOrSale}>{formatMessage({ id: 'sale', defaultMessage: 'SALE' })}</span>
        )}
        {!isSale && isNew && (
          <span className={styles.newOrSale}>{formatMessage({ id: 'new', defaultMessage: 'NEW' })}</span>
        )}
        <div className={styles['product-name']}>{productName}</div>
      </div>
      {currentOptionsAvailable.show && (
        <div>
          {formatMessage({
            id: 'product.options',
            defaultMessage: '{optionsAmount} Options Available',
            values: { optionsAmount: currentOptionsAvailable.count ?? '' },
          })}
        </div>
      )}
      {Boolean(promoMessage) && isUsaStore && <div className={styles['promo-message']}>{promoMessage}</div>}
      <Price showPrice={showPrice} product={product} />
    </div>
  );
};

export default ProductDescription;
